<template>
    <el-dialog title="编辑" :visible="visible" width="60%" @close="close">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="选择医院" prop="hospital_id" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <select-hospital v-model="form.hospital_id" />
            </el-form-item>
            <!-- <el-form-item label="选择医生">
                <el-checkbox-group v-model="form.doctor">
                    <el-checkbox label="男" name="doctor"></el-checkbox>
                    <el-checkbox label="女" name="doctor"></el-checkbox>
                </el-checkbox-group>
            </el-form-item> -->
            <el-form-item label="选择日期" prop="dates" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-date-picker type="dates" value-format="yyyy-MM-dd" v-model="form.dates" placeholder="选择一个或多个日期"
                    style="width: 100%" />
            </el-form-item>
            <el-form-item label="选择时间" prop="times">
                <time-inputs v-model="form.times" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    data() {
        return {
        }
    },
    methods: {
        async submit() {
            const valid = await this.$refs.form.validate().catch(error => {return false});
            if (!valid) return
            this.$http.post('/enterprise/shihua/scheduling/times', this.form)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success('保存成功')
                        this.$emit('update')
                        this.$emit('close')
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}

.el-form-item {
    /* margin-bottom: 5px; */
}
</style>
