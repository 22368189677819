<template>
    <zk-search-form-inline :form="search" @search="$emit('search', search)">
        <el-form-item label="医院">
            <select-hospital v-model="search.hospital_id" />
        </el-form-item>
        <el-form-item label="范围">
            <el-date-picker v-model="search.daterange" size="small" type="daterange"
                value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    props: ['search'],
    data() {
        return {
            // form: {}
        }
    }
}
</script>

